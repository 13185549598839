


























































import { ElForm } from 'element-ui/types/form';
import { isEmpty, map, round } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { WecomVideoServices } from '@/services/wecom-video-service'; //新的接口 换这里
import moment from 'moment';

@Component({})
export default class ChannelManagement extends Vue {
    [x: string]: any;
    public tableData: any[] = [];
    public visible: Boolean = false;
    public loading: Boolean = false;
    public form: any = {
        description: '',
        pageSize: 10,
        pageNo: 1,
        sourceIds: [],
        actionIds: [],
        createdTime: [],
        visit: ''
    }
    public actions: any = [];
    public sourceList: any[] = [];
    @Inject(WecomVideoServices)
    public wecomVideoServices!: WecomVideoServices;
    public search() {
        this.form.pageNo = 1;
        this.form.pageSize = 10;
        this.getList()
    }
    public async getSourceList(): Promise<void> {
        const res = await this.wecomVideoServices.getAuthSourceOptions();
        this.sourceList = res;
    }
    //获取数据源
    public async getList() {
        this.loading = true;
        try {
            const {
                pageSize,
                pageNo,
                sourceIds,
                createdTime,
                actionIds,
                visit,
                description
            } = this.form;
            const params: Dict<any> = { pageSize, pageNo, sourceIds, actionIds, description, visit };
            if (!isEmpty(createdTime)) {
                params.startTime = moment(createdTime[0]).startOf('days').valueOf();
                params.endTime = moment(createdTime[1]).endOf('days').valueOf();
            }
            const res = await this.wecomVideoServices.getVideoStatement(params);
            this.formData(res)
            this.form.total = res.total;
        } catch (e) {

        }
        this.loading = false;

    }
    public formatter(time: any, format: string) {
        return moment().startOf('days').add('s', time).format(format);
    }
    public getActionList() {
        this.wecomVideoServices.getActions().then((res) => {
            if (res) {
                this.actions = res.records;
            }
        });
    }
    public async formData(res: any) {
        let data = res.records;
        data = map(data, (item) => {
            return {
                ...item,
                videoTime: this.formatter(item.videoTime || 0, 'HH:mm:ss'),
                totalTime: this.formatter(item.totalTime || 0, 'HH:mm:ss'),
                viewTime: this.formatter(item.viewTime || 0, 'HH:mm:ss'),
                percentage: `${round((item.percentage * 100 || 0), 2)}%`,
            }
        })
        this.tableData = data;
    }

    public handleSizeChange(size: number) {
        this.form.pageSize = size;
        this.getList();
    }
    public exportStatement() {
        // 导出
        const loading = this.$loading({
            text: '导出文件中...',
        })
        const {
            pageSize,
            pageNo,
            sourceIds,
            createdTime,
            actionIds,
            visit,
            description
        } = this.form;
        const params: Dict<any> = { pageSize, pageNo, sourceIds, actionIds, description, visit };
        if (!isEmpty(createdTime)) {
            params.startTime = moment(createdTime[0]).startOf('days').valueOf();
            params.endTime = moment(createdTime[1]).endOf('days').valueOf();
        }

        this.wecomVideoServices.exportStatement(params, `视频行为报表_${moment().format('YYYY年MM月DD日_HH时mm分ss秒')}.xlsx`).finally(() => {
            loading.close();
        });
    }
    public handleCurrentChange(current: number) {
        this.form.pageNo = current;
        this.getList();
    }
    public created() {
        this.getSourceList();
        this.getList();
        this.getActionList();
    }
    public mounted() {
        document.onkeydown = e => {
            let key = (window.event as any).keyCode;
            if (key == 13) {
                this.search();
            }
        };
    }
}
