import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { AxiosRequestConfig } from 'axios';

export class WecomVideoServices {
  @Inject(HttpService) private http!: HttpService;

  public getAgentList(): Promise<any> {
    return this.http.get('/wecom-video-admin/channel/options');
  }
  public createSource(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-video-admin/source/create', param);
  }
  public getSouceDetail(id: any): Promise<any> {
    return this.http.get(`/wecom-video-admin/source/detail/${id}`);
  }
  public deleteSource(param: Dict<any>): Promise<any> {
    return this.http.post(`/wecom-video-admin/source/delete/${param.id}`, param);
  }
  public checkIsAdmin(): Promise<any> {
    return this.http.get('/wecom-video-admin/user/isAdmin');
  }
  public getSourceList(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-video-admin/source/page', param);
  }
  public updateSource(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-video-admin/source/update', param);
  }
  public getSourceOptions():Promise<any> {
    return this.http.get('/wecom-video-admin/source/options');
  }
  public getAuthSourceOptions():Promise<any> {
    return this.http.get('/wecom-video-admin/source/auth/options');
  }
  public getVideoList(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-video-admin/video/page', param);
  }
  
  public getUsers(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/user/selectPage', param);
  }
  public getUserGroups(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/userGroup/selectPage', param);
  }
  public getAllUserGroups(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/userGroup/customer/selectPage', param);
  }
  public videoUpload(param: Dict<any>, restConfig?: AxiosRequestConfig):Promise<any> {
    return this.http.post('/wecom-video-admin/aws/s3/video/create', param, undefined, restConfig);
  }
  public posterUpload(param: Dict<any>, restConfig ?: AxiosRequestConfig):Promise<any> {
    return this.http.post('/wecom-video-admin/aws/s3/video/poster/create', param, undefined, restConfig);
  }
  public videoDetail(id: string | number):Promise<any> {
    return this.http.get(`/wecom-video-admin/video/agent/video/${id}`);
  }
  public videoRelease(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/video/agent/video/release', param);
  }
  public videoDelete(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/video/agent/video/delete', param);
  }
  public videoExport(param: Dict<any>, name: string):Promise<any> {
    return this.http.downloadFile('/wecom-video-admin/video/page/export', param, name);
  }
  public offSelves(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/video/agent/video/offSelves', param);
  }
  public videoCancel(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/video/agent/video/cancel', param);
  }
  
  public videoPublish(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/video/agent/video/publish', param);
  }
 
  public getSharePhoto():Promise<any> {
    return this.http.get('/wecom-video-admin/video/default/shareWechat');
  }
  public getAllDefaultPhoto():Promise<any> {
    return this.http.get('/wecom-video-admin/video/default/properties');
  }
  public getMomentSinglePageDefaultPic():Promise<any> {
    return this.http.get('/wecom-video-admin/video/default/sharePoster');
  }
  public getVideoStatement(param: Dict<any>):Promise<any> {
    return this.http.post('/wecom-video-admin/aws/s3/report/list', param);
  }
  public getActions():Promise<any> {
    return this.http.get('/wecom-video-admin/aws/s3/report/action/list');
  }
  public exportStatement(param: Dict<any>, name: string):Promise<any> {
    return this.http.downloadFile('/wecom-video-admin/aws/s3/report/page/export', param, name);
  }
}
